import React, { useEffect, useMemo, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import SearchIcon from "@mui/icons-material/Search";
import background from "../../images/background.png";
import Footer from "../../shared/components/Footer";
import ShowMore from "../../images/icons/show-more.svg";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import { TextField, Select, MenuItem, FormControl, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ResourceCard } from "./components";

import "./Resources.css";

const Resources = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(2);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 4);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const res = await axiosNoAuthInstance.get("/wagtail-api/v2/resources/?fields=date,title,teaser,body,resource_type,keywords,authors(name),organisations(name),thumbnail_image_206x293,access_document,access_link,featured,featured_weight,publication_date,publication_date_year");
      setData(res?.data?.items);
      const years = new Set(res?.data?.items.map((item) => item.publication_date_year));
      setUniqueYears([...years]);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    loadData();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm === "" && selectedYear === "") {
        setFilteredData(data);
      } else {
        setFilteredData(
          data.filter((resource) => {
            const resourceData = [resource.title, resource.teaser, resource.publication_date, resource.resource_type, ...resource.keywords, ...resource.authors.map((a) => a.name), ...resource.organisations.map((o) => o.name)].join(" ").toLowerCase();
            const matchesSearchTerm = searchTerm === "" ? true : resourceData.includes(searchTerm.toLowerCase());
            const matchesSelectedYear = selectedYear === "" ? true : resource.publication_date_year === selectedYear;
            return matchesSearchTerm && matchesSelectedYear;
          })
        );
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, selectedYear, data]);

  const featuredResources = useMemo(() => {
    return filteredData?.filter((resource) => resource.featured).sort((a, b) => b.featured_weight - a.featured_weight);
  }, [filteredData]);
  const latestResources = useMemo(() => {
    const resources = filteredData?.filter((resource) => !resource.featured);
    return resources.sort((a, b) => {
      const dateA = new Date(a.publication_date);
      const dateB = new Date(b.publication_date);
      return dateB - dateA;
    });
  }, [filteredData]);

  const resourcesForCurrentPage = latestResources.slice(0, itemsToShow);
  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between overflow-y-scroll no-scrollbar"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <Scrollbar
          style={{ width: "100%", height: "100%" }}
          thumbYProps={{
            renderer: ({ style, ...props }) => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} style={{ ...style, backgroundColor: "#FFF", width: "8px", opacity: "0.5" }} ref={elementRef} className="tHuMbY" />;
            },
          }}
        >
          <div className="flex flex-col items-center z-10 p-5 pb-20">
            <h1 className="text-7xl text-white p-8">RESOURCES</h1>
            <div className="bg-white bg-opacity-100 border rounded pt-20 pb-20 border-0" style={{ width: "80%", paddingRight: "10%", paddingLeft: "10%" }}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                  <CircularProgress style={{ color: "#F0A400" }} size="50px" />
                </div>
              ) : (
                <>
                  <div className="mb-10" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ position: "relative", marginRight: "1rem" }}>
                      <TextField
                        className="search"
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#F0A400" }} />
                            </InputAdornment>
                          ),
                          endAdornment: searchTerm && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setSearchTerm("")} edge="end">
                                <ClearIcon style={{ color: "#F0A400" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <FormControl className="search-select">
                      <Select labelId="year-select-label" value={selectedYear} onChange={handleYearChange} displayEmpty>
                        <MenuItem value="">Year</MenuItem>
                        {uniqueYears.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {filteredData.length === 0 ? (
                    <h2
                      style={{
                        fontSize: 32,
                        fontWeight: "bold",
                      }}
                      className="font-sans pb-5"
                    >
                      No resources were found
                    </h2>
                  ) : (
                    <>
                      {featuredResources.length > 0 && (
                        <div>
                          <h2 className="font-sans pb-5" style={{ fontSize: "50px", fontWeight: 600 }}>
                            Featured Research
                          </h2>
                          {featuredResources
                            ?.filter((resource) => resource.featured)
                            ?.map((resource, index) => (
                              <div className="border-b-2 last:border-b-0 mt-10 pb-10" style={{ borderColor: "#D8D8D8" }}>
                                <ResourceCard key={`featured-${index}`} resource={resource} />
                              </div>
                            ))}
                        </div>
                      )}
                      {latestResources.length > 0 && (
                        <div>
                          <h2 className="font-sans pb-5 pt-10" style={{ fontSize: "50px", fontWeight: 600 }}>
                            Latest Resources
                          </h2>
                          {resourcesForCurrentPage
                            ?.filter((resource) => !resource.featured)
                            ?.map((resource, index) => (
                              <div className="border-b-2 mt-10 pb-10" style={{ borderColor: "#D8D8D8", borderBottom: index === resourcesForCurrentPage.length - 1 ? "none" : "" }}>
                                <ResourceCard key={`latest-${index}`} resource={resource} />
                              </div>
                            ))}
                          <div className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {itemsToShow < latestResources.length && (
                              <button className="cursor-pointer" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={handleShowMore}>
                                <img src={ShowMore} alt="Show More" />
                                <span className="font-inter pt-2" style={{ fontSize: "14px", fontWeight: 700, color: "#F0A400" }}>
                                  Show More
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Footer />
        </Scrollbar>
      </div>
    </div>
  );
};

export default Resources;
