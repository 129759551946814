import React from "react";
import background from "../../images/background.png";
import Footer from "../../shared/components/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { routeConstants } from "../../shared/constants";
import { NavLink } from "react-router-dom";

function MapExplorerLandingPage() {
  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="flex flex-col items-center z-10 p-5">
          <h1 className="text-7xl text-white p-8">MAP EXPLORER</h1>
          <div
            className="bg-white bg-opacity-20 border rounded p-6 border-0 flex flex-col items-center"
            // grey option ... className="bg-opacity-80 border rounded p-6 border-0 flex flex-col items-center"
            // grey option ... style={{ width: "70%", backgroundColor: "rgba(105, 105, 105, 0.8)", border: "1px rgba(255, 255, 255, 0.2) solid"}}
            style={{ width: "70%", backgroundColor: "#282F3980" }}
          >
            <h2
              style={{
                fontSize: 20,
                color: "white",
                width: "80%",
                textAlign: "center",
              }}
              className="font-sans"
            >
              The map explorer allows you to 'pick and choose' what you're interested in visualising. Just follow these easy steps:
            </h2>
            <div className="flex justify-between mx-10 mt-10">
              <p style={{ width: "45%", color: "white", margin: 0 }} className="font-sans">
                Step 1: Select your metro (or "all municipalities" for nationwide comparisons)
                <br />
                <br />
                Step 3: Select your aggregation (such as by industry or sex) or otherwise skip
                <br />
                <br />
                Step 5: Select your year
              </p>
              <p style={{ width: "45%", color: "white" }} className="font-sans">
                Step 2: Select your output (such as employees, establishments or median income)
                <br />
                <br />
                Step 4: Select your view (such as absolute totals or percentages or change over time)
              </p>
            </div>
          </div>
          <NavLink to={routeConstants.TAX_DATA_FORM}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: 50,
                padding: 8,
                paddingLeft: 14,
                color: "#F0A400",
                marginTop: 50,
              }}
              className="font-sans"
            >
              Go To Map Explorer
              <ArrowForwardIosIcon />
            </button>
          </NavLink>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default MapExplorerLandingPage;
