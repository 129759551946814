import React from "react";
import background from "./images/background.png";
import download from "./images/download.png";
import explorer from "./images/explorer.png";
import dashboard from "./images/dashboard.png";
import resources from "./images/resources.png";
import Footer from "./shared/components/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { routeConstants } from "./shared/constants";
import { NavLink } from "react-router-dom";

function App() {
  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="flex flex-col items-center z-10 p-5">
          <h1 className="text-7xl text-amber-500 mt-5">
            <span style={{ color: "white" }}>EXPLORE</span> SOUTH AFRICAN TAX DATA
          </h1>
          <p
            style={{
              width: "80%",
              textAlign: "center",
              fontSize: 20,
              color: "white",
              padding: 10,
            }}
            className="font-sans"
          >
            There is a glaring gap in the availability of credible spatial economic data in South Africa. This ambitious project draws on administrative tax records to generate new insights about the economy of South African cities and regions
          </p>
          <div className="bg-white bg-opacity-20 border rounded p-5 border-0 flex justify-between" style={{ width: "70%", backgroundColor: "#282F3980" }}>
            <NavLink to={routeConstants.DASHBOARDS_LANDING}>
              <div className="p-5 flex flex-col items-center">
                <img src={dashboard} alt="dashboard" />
                <h3 className="text-white text-lg mt-5 font-sans">DASHBOARDS</h3>
                <p
                  style={{
                    width: "90%",
                    fontSize: 16,
                    textAlign: "center",
                    color: "white",
                    margin: 0,
                  }}
                  className="font-sans"
                >
                  Go on a journey to discover distinctive features about local economies
                </p>
              </div>
            </NavLink>
            <NavLink to={routeConstants.TAX_DATA_LANDING}>
              <div className="p-5 flex flex-col items-center">
                <img src={explorer} alt="map explorer" />
                <h3 className="text-white mt-5 text-lg font-sans">MAP EXPLORER</h3>
                <p
                  style={{
                    width: "90%",
                    fontSize: 16,
                    textAlign: "center",
                    color: "white",
                    margin: 0,
                  }}
                  className="font-sans"
                >
                  An open ended tool to visualize spatial tax data based on your interests
                </p>
              </div>
            </NavLink>
            <NavLink to={routeConstants.DOWNLOAD_DATA_LANDING}>
              <div className="p-5 flex flex-col items-center">
                <img src={download} alt="download data" />
                <h3 className="text-white mt-5 text-lg font-sans">DOWNLOAD DATA</h3>
                <p
                  style={{
                    width: "90%",
                    fontSize: 16,
                    textAlign: "center",
                    color: "white",
                    margin: 0,
                  }}
                  className="font-sans"
                >
                  Get access to the raw data, metadata and methodology user guide
                </p>
              </div>
            </NavLink>
            <NavLink to={routeConstants.RESOURCES}>
              <div className="p-5 flex flex-col items-center">
                <img style={{ padding: "1rem", width: "100px" }} src={resources} alt="resources" />
                <h3 className="text-white mt-5 text-lg font-sans">RESOURCES</h3>
                <p
                  style={{
                    width: "90%",
                    fontSize: 16,
                    textAlign: "center",
                    color: "white",
                    margin: 0,
                  }}
                  className="font-sans"
                >
                  Explore research and related content based on spatial tax data
                </p>
              </div>
            </NavLink>
          </div>
          <NavLink to={routeConstants.ABOUT_THE_PROJECT}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: 50,
                padding: 8,
                paddingLeft: 14,
                color: "#F0A400",
                marginTop: 30,
              }}
              className="font-sans"
            >
              Learn More
              <ArrowForwardIosIcon />
            </button>
          </NavLink>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
