import background from "../../images/background.png";
// import Footer from "../../shared/components/Footer";
// import seadboxlogo from "../../images/about-the-project-sead-logo.png";

import validator from "validator";
import { Scrollbar } from "react-scrollbars-custom";

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { routeConstants, countryOptions, sectorOptions } from "../../shared/constants";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const UserEmail = () => {
  const navigate = useNavigate();
  const axiosRegisterInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/user-api/",
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  const [email, setEmail] = useState("");

  const [captchaResponse, setCaptchaResponse] = useState();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    setCaptchaResponse(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    document.querySelector(".grecaptcha-badge")?.classList.add("show");

    return () => {
      document.querySelector(".grecaptcha-badge")?.classList.remove("show");
    };
  }, []);

  useEffect(() => {
    if (navigate) {
      if (localStorage.getItem("accessToken")) {
        navigate("/");
      }
    }
  }, [navigate]);

  const canEmail = () => {
    return email.trim() !== "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)) { //Either works
    if (!validator.isEmail(email)) {
      return swal("Invalid Email Format", "Please check your email format", "info", {
        buttons: false,
        timer: 2500,
      });
    }

    try {
      await axiosRegisterInstance.post("/email/mobile-users-email/", {
        email: email,
        captchaResponse,
      });

      swal("Success", "Email Reminder sent", "success", {
        buttons: false,
        timer: 1000,
      });
    } catch (error) {
      if (error?.response?.status === 404 || error?.response?.status === 500) {
        swal("Failed", `${error?.response?.data?.detail}`, "error", {
          timer: 5000,
          buttons: false,
        });
      } else if (error?.response == null) {
        swal("No Data available!", "Please select other filters", "error", {
          timer: 10000,
          buttons: false,
        });
      } else {
        swal("Failed", "error", "error", {
          buttons: false,
          timer: 2000,
        });
      }
    }
    handleReCaptchaVerify();
  };

  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between overflow-y-scroll no-scrollbar"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <Scrollbar
          style={{ width: "100%", height: "100%" }}
          thumbYProps={{
            renderer: ({ style, ...props }) => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} style={{ ...style, backgroundColor: "#FFF", width: "8px", opacity: "0.5" }} ref={elementRef} className="tHuMbY" />;
            },
          }}
        >
          <div className="flex flex-col items-center z-10 p-5 pb-20">
            <h1 className="text-6xl text-white p-8">WELCOME!</h1>
            <div className="bg-white bg-opacity-100 border rounded pt-20 pb-20 border-0 flex flex-col items-center" style={{ width: "80%" }}>
              <h2
                style={{
                  fontSize: 22,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans pb-5"
              >
                Let's get you exploring our data on a larger device!
              </h2>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans"
              >
                This portal is desktop optimised only
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                This website is only optimised for desktop viewing. This means any screen under 1024px wide does not behave in a desirable way, so instead we are showing you this message along with a optional email reminder below.
              </p>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans"
              >
                Request an email reminder to a larger device
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                To send a link to your desktop / laptop please supply your email address below and we will send the link to you allowing you to view the site on a larger device.
              </p>
              {/* FORM START */}
              <form className="items-center" style={{ width: "80%" }} noValidate onSubmit={handleSubmit}>
                <h3
                  style={{
                    fontSize: 16,
                    color: "grey",
                    width: "80%",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                  className="font-sans"
                >
                  Enter your email
                </h3>
                <div style={{ color: "grey", width: "100%" }} className="grid grid-cols-1 md:grid-cols-1">
                  <input type="email" className="form-control placeholder-grey bg-gray-200" placeholder="example@email.com" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    color: "grey",
                    width: "80%",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                  className="font-sans"
                >
                  Privacy notice
                </h3>
                <p style={{ color: "grey", width: "80%" }} className="font-sans">
                  Note: We will not store your email address and we shall only use it for the purpose of this notification.
                </p>

                <div className="w-full mx-auto my-5 md:px-0">
                  <button type="submit" className={`btn ${canEmail() ? "btn-secondary" : "btn-disabled"} w-full !px-0`} disabled={!canEmail()}>
                    Email me the link
                  </button>
                </div>
              </form>
              {/* FORM END */}
            </div>
          </div>
          {/* <Footer /> */}
        </Scrollbar>
      </div>
    </div>
  );
};

export default UserEmail;

// const MobilePlaceHolder = () => {
//   return (
//     <div className="relative">
//       <div
//         style={{
//           height: "calc(100vh - 80.88px)",
//           backgroundImage: `url(${background})`,
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           backgroundSize: "cover",
//         }}
//         className="flex flex-col justify-between overflow-y-auto"
//       >
//         <div className="absolute inset-0 bg-black bg-opacity-50"></div>
//         <div className="flex flex-col items-center z-10 p-5 pb-20">
//           <h1 className="text-7xl text-white p-8">
//             SMALL SCREEN USERS
//           </h1>
//           <div
//             className="bg-white bg-opacity-100 border rounded p-6 border-0 flex flex-col items-center"
//             style={{ width: "80%" }}
//           >
//             <p className="font-sans m-10">
//               <img style={{ width: "230px" }} src={seadboxlogo} alt="Sead vertical unboxed logo" />
//             </p>
//             <h2
//               style={{
//                 fontSize: 22,
//                 color: "grey",
//                 width: "80%",
//                 textAlign: "left",
//                 fontWeight: "bold"
//               }}
//               className="font-sans pb-5"
//             >
//               A Notice to Mobile and Tablet users
//             </h2>
//             <h3
//               style={{
//                 fontSize: 16,
//                 color: "grey",
//                 width: "80%",
//                 textAlign: "left",
//                 fontWeight: "bold"
//               }}
//               className="font-sans"
//             >
//               Desktop optimised only
//             </h3>
//             <p style={{ color: "grey", width: "80%" }} className="font-sans">
//             This website is only optimised for desktop viewing. This means any screen under 1024px wide does not behave in a desirable way, so instead we are showing you this message along with a optional email reminder below.
//             </p>
//             <h3
//               style={{
//                 fontSize: 16,
//                 color: "grey",
//                 width: "80%",
//                 textAlign: "left",
//                 fontWeight: "bold"
//               }}
//               className="font-sans"
//             >
//               Request an email reminder now
//             </h3>
//             <p style={{ color: "grey", width: "80%" }} className="font-sans">To send a link to your desktop / laptop please supply your email address below and we will send the link to you allowing you to view the site on a larger device.
//             </p>
//             {/* <form field to receive email address> */}
//             <p style={{ color: "grey", width: "80%" }} className="font-sans">Note: We will not store your email address and we shall only use it for the purpose of this notification.</p>
//             {/* <submit button as per website style> */}

//             <h2
//               style={{
//                 fontSize: 38,
//                 color: "grey",
//                 width: "80%",
//                 textAlign: "center",
//                 fontWeight: "bold"
//               }}
//               className="font-sans m-20"
//             >
//               CONTACT
//             </h2>
//             <h3
//               style={{
//                 fontSize: 16,
//                 color: "grey",
//                 width: "80%",
//                 textAlign: "left",
//                 fontWeight: "bold"
//               }}
//               className="font-sans mb-30"
//             >
//               Human Sciences Research Council
//             </h3>
//             <p style={{ color: "grey", width: "80%" }} className="font-sans mb-20">
//               Nomonde Mathambo: <a href="mailto:nmathambo@hsrc.ac.za?subject=Enquiry from Spatial Tax Data Website">nmathambo@hsrc.ac.za</a>
//             </p>

//           </div>
//         </div>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default MobilePlaceHolder;
