import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "tw-elements";
//import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Media from "react-media";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import { Container } from 'react-bootstrap'
//import Header from './components/Header'
import { routeConstants, reCaptchaSiteKey } from "./shared/constants";
import SidebarRight from "./components/Sidebars/SidebarRight";
import UserRegister from "./components/User/Register";
import UserLogin from "./components/User/Login";
import UserLogout from "./components/User/Logout";
import UserProfile from "./components/User/Profile";
import DownloadDataHome from "./components/DownloadData/DownloadDataHome";
import DownloadDataFilters from "./components/DownloadData/DownloadFiltersForm";
import DownloadHistory from "./components/DownloadData/DownloadHistory";
import TaxDataHome from "./components/TaxData/TaxDataHome";
import TaxDataFilters from "./components/TaxData/TaxDataFiltersForm";
import HsrcAdminHome from "./components/HsrcAdmin/HsrcAdminHome";
import HsrcAdminConfigTable from "./components/HsrcAdmin/ManageConfigTable";
import HsrcAdminDataSets from "./components/HsrcAdmin/ManageDataSets";
import HsrcAdminUsers from "./components/HsrcAdmin/HsrcAdminUsers";
import VerifyEmail from "./components/User/VerifyEmail";
import ResetPassword from "./components/User/ResetPassword";
import AboutUs from "./components/Home/AboutUs";
import MobilePlaceHolder from "./components/Home/MobilePlaceHolder";
import Resources from "./components/Home/Resources";
import DashboardsOverview from "./components/Dashboards/DashboardsOverview";
import DashboardLandingPage from "./components/Dashboards/DashboardLandingPage";
import DownloadDataLandingPage from "./components/DownloadData/DownloadDataLandingPage";
import MapExplorerLandingPage from "./components/TaxData/MapExplorerLandingPage";
import PdfPreview from "./components/PdfPreview/PdfPreview";
import Resource from "./components/Home/Resource";
import ErrorPage from "./components/Home/ErrorPage";

// Google Tag Managaer
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-594VKMV",
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
    <React.StrictMode>
      <BrowserRouter>
        <SidebarRight />
        <Media query="(min-width: 1024px)">
          {(matches) =>
            matches ? (
              <Routes>
                <Route path="/" element={<App />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_LANDING} element={<DownloadDataLandingPage />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_HOME} element={<DownloadDataHome />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_FILTER_FORM} element={<DownloadDataFilters />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_HISTORY} element={<DownloadHistory />} exact />
                <Route path={`${routeConstants.DOWNLOAD_DATA_HISTORY}/:userId`} element={<DownloadHistory />} exact />
                <Route path={routeConstants.TAX_DATA_LANDING} element={<MapExplorerLandingPage />} exact />
                <Route path={routeConstants.TAX_DATA_HOME} element={<TaxDataHome />} exact />
                <Route path={routeConstants.TAX_DATA_FORM} element={<TaxDataFilters />} exact />
                <Route path={routeConstants.HSRC_ADMIN_HOME} element={<HsrcAdminHome />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_CONFIG_TABLE} element={<HsrcAdminConfigTable />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_DATA_SETS} element={<HsrcAdminDataSets />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_USERS} element={<HsrcAdminUsers />} exact />
                <Route path={routeConstants.LOGIN} element={<UserLogin />} exact />
                <Route path={`${routeConstants.LOGIN}/:redirect`} element={<UserLogin />} exact />
                <Route path={`${routeConstants.LOGIN}/:redirect/:dashboard`} element={<UserLogin />} exact />
                <Route path={routeConstants.PROFILE} element={<UserProfile />} exact />
                <Route path={`${routeConstants.PROFILE}/:userId`} element={<UserProfile />} exact />
                <Route path={routeConstants.REGISTER} element={<UserRegister />} exact />
                <Route path={routeConstants.LOGOUT} element={<UserLogout />} exact />
                <Route path={`${routeConstants.VERIFY_EMAIL}/:email`} element={<VerifyEmail />} exact />
                <Route path={`${routeConstants.RESET_PASSWORD}/:email/:token`} element={<ResetPassword />} exact />
                <Route path={routeConstants.ABOUT_THE_PROJECT} element={<AboutUs />} exact />
                <Route path={routeConstants.RESOURCES} element={<Resources />} exact />
                <Route path={`${routeConstants.RESOURCES}/:slug`} element={<Resource />} exact />
                <Route path={routeConstants.DASHBOARDS} element={<Navigate to={routeConstants.DASHBOARDS_LANDING} />} exact />
                <Route path={`${routeConstants.DASHBOARDS}`} element={<Navigate to={routeConstants.DASHBOARDS_LANDING} />} exact />
                <Route path={`${routeConstants.DASHBOARDS}`} element={<DashboardsOverview />} exact />
                <Route path={`${routeConstants.DASHBOARDS}/:dashboard`} element={<DashboardsOverview />} exact />
                <Route path={`${routeConstants.PDF_PREVIEW}/:pdf`} element={<PdfPreview />} exact />
                <Route path={`${routeConstants.DASHBOARDS_LANDING}`} element={<DashboardLandingPage />} exact />
                <Route path={`${routeConstants.ERROR}`} element={<ErrorPage />} exact />
                {/* IN CASE SOMEONE IS TESTING IN BOTH DIRECTIONS */}
                <Route path={routeConstants.MOBILE_HOLDING} element={<Navigate to="/" />} exact />
              </Routes>
            ) : (
              <Routes>
                <Route path={routeConstants.MOBILE_HOLDING} element={<MobilePlaceHolder />} exact />
                {/* REDIRECTS BELOW */}
                <Route path="/" element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_LANDING} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_HOME} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_FILTER_FORM} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.DOWNLOAD_DATA_HISTORY} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.DOWNLOAD_DATA_HISTORY}/:userId`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.TAX_DATA_LANDING} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.TAX_DATA_HOME} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.TAX_DATA_FORM} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.HSRC_ADMIN_HOME} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_CONFIG_TABLE} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_DATA_SETS} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.HSRC_ADMIN_MANAGE_USERS} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.LOGIN} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.LOGIN}/:redirect`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.LOGIN}/:redirect/:dashboard`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.PROFILE} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.PROFILE}/:userId`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.REGISTER} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.LOGOUT} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.VERIFY_EMAIL}/:email`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.RESET_PASSWORD}/:email/:token`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.ABOUT_THE_PROJECT} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.RESOURCES} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.RESOURCE} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={routeConstants.DASHBOARDS} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.DASHBOARDS}`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.DASHBOARDS}`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.DASHBOARDS}/:dashboard`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.PDF_PREVIEW}/:pdf`} element={<PdfPreview />} exact />
                <Route path={`${routeConstants.DASHBOARDS_LANDING}`} element={<Navigate to={routeConstants.MOBILE_HOLDING} />} exact />
                <Route path={`${routeConstants.ERROR}`} element={<ErrorPage />} exact />
              </Routes>
            )
          }
        </Media>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
